<template>
  <div id="app">

    <NavBar v-if="$route.meta.navBarFlag" />


    <keep-alive :include="include">
      <router-view v-if="isRouterAlive && $route.meta.keepAlive" />
    </keep-alive>


    <router-view v-if="isRouterAlive && !$route.meta.keepAlive" />

    <!-- <router-view v-if="isRouterAlive" /> -->

    <TabBar v-if="$route.meta.tabBarFlag" />
    <TabBarSupp v-if="$route.meta.tabBarFlag" />
    <TabBarShare v-if="$route.meta.tabBarFlag" />
  </div>
</template>

<script>
import NavBar from "@components/navBar";
import TabBar from "@components/tabBar";
import TabBarSupp from "@components/tabBarSupp";
import TabBarShare from "@components/tabBarShare";
import * as orderApi from "@/api/order";

export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
      carCount: 0,


    }
  },
  components: {
    TabBar,
    NavBar,
    TabBarSupp,
    TabBarShare
  },
  data() {
    return {
      isRouterAlive: true,
      accountType: JSON.parse(
        sessionStorage.getItem("userInfoXpz")
      )?.accountType,
      include: []
    }
  },
  watch: {
    $route(to, from) {
      //如果 要 to(进入) 的页面是需要 keepAlive 缓存的，把 name push 进 include数组
      if (to.meta.keepAlive) {
        !this.include.includes(to.name) && this.include.push(to.name);
      }
      //如果 要 form(离开) 的页面是 keepAlive缓存的，
      //再根据 deepth 来判断是前进还是后退
      //如果是后退
      if (from.meta.keepAlive && to.meta.deepth < from.meta.deepth) {
        var index = this.include.indexOf(from.name);
        index !== -1 && this.include.splice(index, 1);
      }
      console.log(this.include)
    }
  },
  mounted() {
    // this.init();
    function onBridgeReady() {
      window.WeixinJSBridge.call('hideToolbar');
    }
    if (typeof window.WeixinJSBridge == "undefined") {
      document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
    } else {
      onBridgeReady();
    }
  },

  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    //获取购物车列表数量
    async getCarCount() {
      let res = await orderApi.carCountInfo();
      if (res.status) {
        this.carCount = res.ret.count
      } else {
        this.$toast(res.msg);
      }
    },
    async init() {
      //根据用户角色显示 要显示的角色首页 0运营端，1客户，2供应商
      let accountType = JSON.parse(
        sessionStorage.getItem("userInfoXpz")
      )?.accountType;
      if (accountType == 1) {
        //  客户端首页
        this.accountType = 1
        this.getCarCount()
      }
      if (accountType == 4) {
        //  客户端首页
        this.accountType = 4
        this.getCarCount()
      }
      if (accountType == 2) {
        //   供应商
        this.accountType = 2
      }
    },
  }
};
</script>

<style lang="scss">
.page {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 1rem;
}

/deep/ .van-nav-bar__title {
  font-size: 0.34rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #2a2b2e;
}
</style>
