<template>
  <div id="header">
    <van-nav-bar :border="false">
      <template #left>
        <van-icon
          name="arrow-left"
          size="22"
          color="#61646A"
          @click="handleBack"
        />
      </template>
    </van-nav-bar>
  </div>
</template>
  
  <script>
export default {
  name: "NavBar",
  data() {
    return {};
  },
  methods: {
    handleBack() {
      this.$router.go(-1);
    },
  },
};
</script>
  
  <style lang="scss">
#header {
  /deep/ .van-nav-bar__left {
    padding: 0 0.16rem !important;
  }
  /deep/ .van-nav-bar__title {
    font-size: 0.34rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #2a2b2e;
  }
}
</style>