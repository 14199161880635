  // *****运营端-仓库模块
  export default[  
{
    path: "/warehouseHome", //仓库首页
    name: "WarehouseHome",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/warehouse/home"),
  },
{
    path: "/fbxListWarehouse", //仓库-FBX发货通知列表
    name: "FbxListWarehouse",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/warehouse/fbxList"),
  },
  {
    path: "/fbxListDetailWarehouse", //仓库-FBX发货通知单(多种状态)确认中、未通过
    name: "FbxListDetailWarehouse",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/warehouse/fbxListDetail"),
  },
  {
    path: "/fbxWaitShipmentWarehouse", //仓库-FBX发货通知单-等待发货
    name: "FbxWaitShipmentWarehouse",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/warehouse/fbxWaitShipment"),
  },
  {
    path: "/fbxWaitReceiptWarehouse", //仓库-FBX发货通知单-等待收货
    name: "FbxWaitReceiptWarehouse",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/warehouse/fbxWaitReceipt"),
  },
  {
    path: "/fbxCompleteWarehouse", //仓库-FBX发货通知单-已完成
    name: "FbxCompleteWarehouse",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/warehouse/fbxComplete"),
  },
]