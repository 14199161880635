//全局混入方法
export default {
  data() {
    return {
    }
  },
  methods: {
    getScrollTop(name){   //获取div到顶部的距离
      let scrollTop=0
      if(name){
        scrollTop=document.querySelector(name).scrollTop;
      }else{
        scrollTop = 0;
      }
      return scrollTop;
    },
    getScrollTo(number,name){  //根据传入的class和高度使得页面保持固定div到顶部的距离
      if(!isNaN(number)!==0){
        this.$nextTick(()=>{
          document.querySelector(name).scrollTo({
              top:number,
              behavior:"instant"
            });
        })
      }
    }
  }
}
