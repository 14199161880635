<template>
  <div id="footer1" v-if="accountType == 2">
    <ul>
      <router-link tag="li" v-for="item in tabbars" :key="item.id" :to="item.path">
        <img class="tabbarImg" v-if="!item.active" :src="item.icon" alt="">
        <img class="tabbarImg" v-else :src="item.iconActive" alt="">
        <span>{{ item.text }}</span>
      </router-link>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TabBar",
  watch: {
    '$route': { // $route可以用引号，也可以不用引号
      handler() {
        console.log(this.$route.path)
        if (this.$route.path == '/mineSupp') {
          this.tabbars[0].active = true
          this.tabbars[1].active = false
          this.tabbars[2].active = false
          return
        }
        if (this.$route.path == '/suppClassification') {
          this.tabbars[0].active = false
          this.tabbars[1].active = true
          this.tabbars[2].active = false
          return

        }
        if (this.$route.path == '/mineHome') {
          this.tabbars[0].active = false
          this.tabbars[1].active = false
          this.tabbars[2].active = true
          return
        }
      },
      deep: true, // 深度监听
      immediate: true, // 第一次初始化渲染就可以监听到
    }
  },
  data() {
    return {
      accountType: 2,
      active: '1',
      tabbars: [
       
        {
          id: 1,
          text: "订单",
          icon: require("@assets/image/tabbar52.png"),
          iconActive: require("@assets/image/tabbar51.png"),
          path: "/mineSupp",
          active: false
        },
        {
          id: 2,
          text: "产品",
          icon: require("@assets/image/tabbar20.png"),
          iconActive: require("@assets/image/tabbar21.png"),
          path: "/suppClassification",
          active: true
        },
        {
          id: 3,
          text: "我的",
          icon: require("@assets/image/tabbar40.png"),
          iconActive: require("@assets/image/tabbar41.png"),
          path: "/mineHome",
          active: false
        }
      ]
    };
  },
  mounted() {
    this.accountType= JSON.parse(
        sessionStorage.getItem("userInfoXpz")
      )?.accountType;
    // this.init();
  },
  methods: {
    async init() {
      //根据用户角色显示 要显示的角色首页 0运营端，1客户，2供应商
      let accountType = JSON.parse(
        sessionStorage.getItem("userInfoXpz")
      )?.accountType;
      if (accountType == 2) {
        //   供应商
        this.accountType = 2
      }
    },
  }

};
</script>

<style lang="scss">
#footer1 {
  width: 100%;
  // height: 1rem;
  padding: .1rem 0 .2rem;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #fff;
  z-index: 10;
}

#footer1 ul {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#footer1 ul li {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#footer1 ul li span {
  font-size: .2rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #464646;
}

#footer1 ul li img {
  width: .48rem;
  height: .48rem;
}

#footer1 ul .router-link-active {
  span {
    color: #FE2C55;
  }
}
</style>