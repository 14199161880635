  // *****运营端-财务模块
  export default[  
    {
        path: "/financialList", //财务审核列表
        name: "FinancialList",
        meta: {
          navBarFlag: false,
          tabBarFlag: false,
          requiredAuth: true,
        },
        component: () => import("@pages/operate/financialReview/financialList"),
      },
    {
        path: "/waitforShipmentFinancial", //待发货
        name: "WaitforShipmentFinancial",
        meta: {
          navBarFlag: false,
          tabBarFlag: false,
          requiredAuth: true,
        },
        component: () => import("@pages/operate/financialReview/waitforShipment"),
      },
    {
        path: "/waitingForLoadingFinancial", //待装车
        name: "WaitingForLoadingFinancial",
        meta: {
          navBarFlag: false,
          tabBarFlag: false,
          requiredAuth: true,
        },
        component: () => import("@pages/operate/financialReview/waitforLoading"),
      },
    {
        path: "/waitingForReceiptFinancial", //待收货
        name: "WaitingForReceiptFinancial",
        meta: {
          navBarFlag: false,
          tabBarFlag: false,
          requiredAuth: true,
        },
        component: () => import("@pages/operate/financialReview/waitingForReceipt"),
      },
    {
        path: "/completedFinancial", //已完成
        name: "CompletedFinancial",
        meta: {
          navBarFlag: false,
          tabBarFlag: false,
          requiredAuth: true,
        },
        component: () => import("@pages/operate/financialReview/completed"),
      },
   
    ]