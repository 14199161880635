import Vue from "vue";

// 支付方式过滤
Vue.filter("payWay", (value) => {
  switch (value) {
    case 1:
      return "微信支付";
    case 2:
      return "会员充值";
  }
});

// 商品价格过滤（保留两位小数）
Vue.filter("price", (val) => {
  if (val) {
    val = "¥" + val.toFixed(2);
  } else {
    val = 0;
    val = "¥" + val.toFixed(2);
  }
  return val;
});

// 价格设置（保留两位小数）
// Vue.filter('money',(val = 0, len = 2)=>{
//     return val && /^\-?\d+(\.\d+)?$/.test(val.toString()) ? (Math.round(val * (10 ** len)) / 10 ** len).toFixed(len) : '0.00'
// })

// 货币过滤（三位加，保留两位小数）
Vue.filter("MoneyFormat", (money) => {
  if (money && money != null) {
    money = String(money);
    var left = money.split(".")[0],
      right = money.split(".")[1];
    var tr = right.substr(2, 1);
    let ri = 0;
    if (tr && tr > 4) {
      ri = Number(right.substr(0, 2)) + 1;
    } else {
      ri = right.substr(0, 2);
    }
    console.log(tr);
    right = right ? (right.length >= 2 ? "." + ri : "." + right + "0") : ".00";
    var temp = left
      .split("")
      .reverse()
      .join("")
      .match(/(\d{1,3})/g);
    return (
      (Number(money) < 0 ? "-" : "") +
      temp.join(",").split("").reverse().join("") +
      right
    );
  } else if (money === 0) {
    return "0.00";
  } else {
    return "";
  }
});

// 年/月/日 日期过滤
Vue.filter("formateTime", (val) => {
  let date = new Date(val);
  let year = date.getFullYear();
  let m = date.getMonth() + 1;
  let month = m > 9 ? m : "0" + m;
  let day = date.getDate();
  val = `${year}/${month}/${day}`;
  return val;
});

// 年/月/日 时/分/秒 时间过滤
Vue.filter("formdate", (val) => {
  const dt = new Date(val);
  const y = dt.getFullYear();
  const m = (dt.getMonth() + 1 + "").padStart(2, "0");
  const d = (dt.getDate() + "").padStart(2, "0");
  const hh = (dt.getHours() + "").padStart(2, "0");
  const mm = (dt.getMinutes() + "").padStart(2, "0");
  const ss = (dt.getSeconds() + "").padStart(2, "0");
  return `${y}/${m}/${d} ${hh}:${mm}:${ss}`;
});

//  小写转大写过滤
Vue.filter("toUpperCase", (e) => {
  return e.toUpperCase();
});

//  大写转小写过滤
Vue.filter("toLowerCase", (e) => {
  return e.toLowerCase();
});

// 隐藏手机号过滤
Vue.filter("phone", (e) => {
  return e && e.toString().replace(/^(\d{3})\d{4}(\d{4})$/, "$1****$2");
});

// 文本长度超出5个溢出隐藏过滤
Vue.filter("ellipsis5", (val) => {
  if (!val) return "";
  if (val.length > 5) {
    return val.slice(0, 5) + "...";
  }
  return val;
});

// 空格过滤
Vue.filter("trim", (val) => {
  return val.replace(/\s*/g, "");
});

// 金额转人民币大写过滤
Vue.filter("MoneyChange", (n) => {
  var fraction = ["角", "分", "厘"];
  var digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "   玖"];
  var unit = [
    ["元", "万", "亿"],
    ["", "拾", "佰", "仟"],
  ];
  var head = n < 0 ? "欠人民币：" : "人民币：";
  n = Math.abs(n);
  var s = "";
  for (var i = 0; i < fraction.length; i++) {
    s += (
      digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]
    ).replace(/零./, "");
  }
  s = s || "整";
  n = Math.floor(n);
  for (var m = 0; m < unit[0].length && n > 0; m++) {
    var p = "";
    for (var j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p;
      n = Math.floor(n / 10);
    }
    s = p.replace(/(零.)*零$/, "").replace(/^$/, "零") + unit[0][m] + s;
    //s = p + unit[0][i] + s;
  }
  return (
    head +
    s
      .replace(/(零.)*零元/, "元")
      .replace(/(零.)+/g, "零")
      .replace(/^整$/, "零元整")
  );
});

// 首字母大写过滤
Vue.filter("indexUper", (val) => {
  return val.charAt(0).toUpperCase() + val.slice(1);
});

//全局过滤器，金额千分位
Vue.filter("moneyFormat", function (value) {
  if (value != undefined) {
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
});
//全局过滤器，数量2位
Vue.filter("qtyFormat", function (value) {
  if (value != undefined) {
    return value.toFixed(2);
  }
});
// bit小数位
Vue.filter("customFormat", function (value, bit) {
  if (!value) {
    value = 0;
  }
  if (value != undefined) {
    var valueCopy = Number(value).toFixed(bit).toString();
    // 截取小数点前的数据
    var intNumber = valueCopy.substring(0, valueCopy.indexOf("."));
    // 小数点前的数据使用千位分割
    intNumber = intNumber.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    // 截取小数点后的数据
    var laterNumber = valueCopy.replace(/\d+\.(\d*)/, "$1");
    //有负数的数据重截取
    laterNumber = laterNumber.replace("-", "");
    // return Number(value).toFixed(bit).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return intNumber + "." + laterNumber;
  }
});

Vue.filter("timestampToTime", function (timestamp) {
  // 计算年月日时分的函数
  var date = new Date(timestamp);
  var Y = date.getFullYear() + "-";
  var M = date.getMonth() + 1 + "-";
  var D = date.getDate() + " ";
  var h = date.getHours() + ":";
  var m = date.getMinutes() + ":";
  var s = date.getSeconds();
  return Y + M + D + h + m + s;
});
Vue.filter("deptLevel", function (value) {
  console.log(value);
  // 计算年月日时分的函数
  var dept = [];
  for (let index = 0; index < value.length; index++) {
    if (value[index].deptLevel == "1") {
      dept.push(value[index]);
    }
  }
  return dept;
});

