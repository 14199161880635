// *****供应商
export default [
  {
    path: "/mineSupp", //我的---底部最左边那个
    name: "MineSupp",
    meta: {
      navBarFlag: false,
      tabBarFlag: true,
      requiredAuth: true,
      deepth: 0.5 // 定义路由的层级
    },
    component: () => import("@pages/supplier/mine"),
  },
  {
    path: "/mineHome", //我的---底部最右边那个
    name: "MineHome",
    meta: {
      navBarFlag: false,
      tabBarFlag: true,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/mineHome"),
  },
  {
    path: "/suppSelectCategory", //供应商选择经营类目
    name: "SuppSelectCategory",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/suppSelectCategory"),
  },

  {
    path: "/suppEntryStudy", //供应商入驻教程
    name: "SuppEntryStudy",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/suppEntryStudy"),
  },
  {
    path: "/uploadEntry", //供应商入驻资料上传
    name: "uploadEntry",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/uploadEntry"),
  },

  {
    path: "/suppClassification", //供应商分类-供应商角色
    name: "suppClassification",
    meta: {
      navBarFlag: false,
      tabBarFlag: true,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/suppClassification"),
  },
  {
    path: "/suppReleases", //供应商发布产品-基本信息
    name: "SuppReleases",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/suppReleases"),
  },

  // {
  //     path:"/expandInfo",//供应商发布产品2-产品拓展信息
  //     name:"ExpandInfo",
  //     meta:{
  //         navBarFlag:false,
  //         tabBarFlag:false,
  //         requiredAuth:true
  //     },
  //     component:()=>import("@pages/supplier/suppReleases/expandInfo")
  // },
  {
    path: "/orderPageSupplier", //订单页
    name: "OrderPageSupplier",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      keepAlive: true, //需要缓存的页面
      deepth: 1
    },
    component: () => import("@pages/supplier/orderPage"),
  },
  {
    path: "/toBePaidSupplier", //待支付页
    name: "ToBePaidSupplier",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    
    },
    component: () => import("@pages/supplier/toBePaid"),
  },
  {
    path: "/waitingForShipmentSupplier", //待发货页
    name: "WaitingForShipmentSupplier",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/waitingForShipment"),
  },
  {
    path: "/waitingForLoadingSuppiler", //待装车页
    name: "WaitingForLoadingSuppiler",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/waitingForLoading"),
  },
  {
    path: "/waitingForReceiptSupplier", //待收货页
    name: "WaitingForReceiptSupplier",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      keepAlive:true,
      deepth:2
    },
    component: () => import("@pages/supplier/waitingForReceipt"),
  },
  {
    path: "/completedSupplier", //已完成页
    name: "CompletedSupplier",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      keepAlive:true,
      deepth:2
    },
    component: () => import("@pages/supplier/completed"),
  },
  {
    path: "/CancledSupplier", //已取消页
    name: "CancledSupplier",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/cancled"),
  },

  {
    path: "/priorityShippingListSupplier", //优先发货清单
    name: "PriorityShippingListSupplier",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/priorityShippingList"),
  },
  {
    path: "/replenishmentListSuppiler", //补货清单
    name: "ReplenishmentListSuppiler",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/replenishmentList"),
  },
  {
    path: "/pickingCart", //拣货车
    name: "PickingCart",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/pickingCart"),
  },
  {
    path: "/shippingPlan", //发货计划
    name: "ShippingPlan",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/shippingPlan"),
  },
  {
    path: "/createShipmentPlan", //创建发货计划
    name: "CreateShipmentPlan",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/createShipmentPlan"),
  },
  {
    path: "/createShippingPlanDetail", //创建发货计划详情
    name: "CreateShippingPlanDetail",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/createShippingPlanDetail"),
  },
  {
    path: "/shippingPlanDetailSupplier", //发货计划详情
    name: "ShippingPlanDetailSupplier",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      deepth: 0.5 // 定义路由的层级
    },
    component: () => import("@pages/supplier/shippingPlanDetail"),
  },
  {
    path: "/unshippedList", //未发货清单
    name: "UnshippedList",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/unshippedList"),
  },
  {
    path: "/storageFeeList", //仓储费清单
    name: "StorageFeeList",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/storageFeeList"),
  },
  {
    path: "/commissionList", //佣金清单
    name: "CommissionList",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      deepth:3
    },
    component: () => import("@pages/supplier/commissionList"),
  },
  {
    path: "/basicService", //基础服务费清单
    name: "BasicService",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/basicService"),
  },
  {
    path: "/warrantyDeposit", //质保金
    name: "WarrantyDeposit",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/warrantyDeposit"),
  },

  // *************协议模块**************
  {
    path: "/orderPageSupplierAgree", //协议-协议列表页
    name: "OrderPageSupplierAgree",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/agreement/orderPage"),
  },
  {
    path: "/selectAgree", //协议-选择协议商品
    name: "SelectAgree",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/agreement/selectAgree"),
  },
  {
    path: "/agreePickingCart", //协议-拣货车
    name: "AgreePickingCart",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/agreement/agreePickingCart"),
  },
  {
    path: "/toBeConfirm", //协议-等待确认
    name: "ToBeConfirm",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/agreement/toBeConfirm"),
  },
  {
    path: "/toBeCancle", //协议-协议已取消
    name: "ToBeCancle",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/agreement/toBeCancle"),
  },
  {
    path: "/toBeComplete", //协议-协议已完成
    name: "ToBeComplete",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/agreement/toBeComplete"),
  },
  {
    path: "/toBeStop", //协议-协议已终止
    name: "ToBeStop",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/agreement/toBeStop"),
  },
  {
    path: "/formalAgreement", //协议-正式协议
    name: "FormalAgreement",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/agreement/formalAgreement"),
  },

  //**********fbX***************** */
  {
    path: "/fbxShipmentRequest", //协议-创建FBX发货通知
    name: "FbxShipmentRequest",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/agreement/fbxShipmentRequest"),
  },

  {
    path: "/fbxList", //协议-FBX发货通知列表
    name: "FbxList",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/agreement/fbxList"),
  },
  {
    path: "/fbxShipmentRequestDetail", //协议-创建FBX发货通知跳转详情
    name: "FbxShipmentRequestDetail",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () =>
      import("@pages/supplier/agreement/fbxShipmentRequestDetail"),
  },
  {
    path: "/fbxUnshippedList", //协议-FBX未发货协议清单
    name: "FbxUnshippedList",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/agreement/fbxUnshippedList"),
  },
  {
    path: "/fbxPickingCart", //协议-FBX拣货车
    name: "FbxPickingCart",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/agreement/fbxPickingCart"),
  },
  {
    path: "/fbxListDetail", //协议-FBX发货通知单(多种状态)确认中、未通过
    name: "FbxListDetail",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/agreement/fbxListDetail"),
  },
  {
    path: "/fbxWaitShipment", //协议-FBX发货通知单-等待发货
    name: "FbxWaitShipment",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/agreement/fbxWaitShipment"),
  },
  {
    path: "/fbxWaitReceipt", //协议-FBX发货通知单-等待收货
    name: "FbxWaitReceipt",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/agreement/fbxWaitReceipt"),
  },
  {
    path: "/fbxComplete", //协议-FBX发货通知单-已完成
    name: "FbxComplete",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/agreement/fbxComplete"),
  },
  {
    path: "/importCuShippingPlan", //协议-导入客户发货计划
    name: "ImportCuShippingPlan",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/agreement/importCuShippingPlan"),
  },
  //**********fbm***************** */
  {
    path: "/fbmList", //协议-FBM入库单列表
    name: "FbmList",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/agreement/fbmList"),
  },
  {
    path: "/fbmShipmentRequest", //协议-创建FBm入库发货通知
    name: "FbmShipmentRequest",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/agreement/fbmShipmentRequest"),
  },
  {
    path: "/fbmShipmentRequestDetail", //协议-创建FBm入库发货通知跳转详情
    name: "FbmShipmentRequestDetail",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () =>
      import("@pages/supplier/agreement/fbmShipmentRequestDetail"),
  },
  {
    path: "/fbmUnshippedList", //协议-FBM未发货协议清单
    name: "FbmUnshippedList",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/agreement/fbmUnshippedList"),
  },
  {
    path: "/fbmPickingCart", //协议-fbm拣货车
    name: "FbmPickingCart",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/agreement/fbmPickingCart"),
  },
  {
    path: "/fbmComplete", //协议-FBM-已完成
    name: "FbmComplete",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/agreement/fbmComplete"),
  },

  {
    path: "/statementAccount", //对账单
    name: "StatementAccount",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/statementAccount"),
  },
  {
    path: "/statementAccountDetail", //账单明细
    name: "StatementAccountDetail",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      deepth:0.5
    },
    component: () => import("@pages/supplier/statementAccountDetail"),
  },
  {
    path: "/dataAnalysis", //数据分析
    name: "DataAnalysis",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/dataAnalysis"),
  },
  {
    path: "/inventoryList", //库存清单
    name: "InventoryList",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/inventoryList"),
  },
  {
    path: "/inventoryListDetail", //出入库明细
    name: "InventoryListDetail",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/inventoryList/detail.vue"),
  },
  {
    path: "/purchaseList", //进销存帐
    name: "PurchaseList",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/purchaseList"),
  },

  /****************************仓库************************ */
  {
    path: "/warehouseManage", //仓库管理
    name: "WarehouseManage",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/warehouse/warehouseManage"),
  },
  {
    path: "/warehouseManageDetail", //仓库管理详情
    name: "WarehouseManageDetail",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/warehouse/warehouseManageDetail"),
  },
  {
    path: "/replenishmentListHouse", //补货清单
    name: "ReplenishmentListHouse",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/warehouse/replenishmentList"),
  },
  {
    path: "/pickingCartHouse", //拣货车
    name: "pickingCartHouse",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/warehouse/pickingCart"),
  },
  {
    path: "/setRestockInventoryList", //设置可补货库存
    name: "SetRestockInventoryList",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () =>
      import("@pages/supplier/setRestockInventory/setRestockInventoryList"),
  },
  {
    path: "/replenishmentInventTableSet", ///设置可补货库存-可补货库存表
    name: "ReplenishmentInventTableSet",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () =>
      import("@pages/supplier/setRestockInventory/replenishmentInventTable"),
  },
  {
    path: "/brandArea", //品牌区域保护首页
    name: "BrandArea",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/mine/brandArea/index.vue"),
  },
  {
    path: "/addBrandArea", //品牌区域保护首页
    name: "AddBrandArea",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/mine/brandArea/addBrandArea.vue"),
  },
  {
    path: "/annualOrderDetailsSupp", //年度订单明细
    name: "AnnualOrderDetailsSupp",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      deepth:0.5
    },
    component: () => import("@pages/supplier/annualOrderDetails"),
  },
  {
    path: "/annualOrderProductDetailsSupp", //年度订单产品明细
    name: "AnnualOrderProductDetailsSupp",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/annualOrderProductDetails"),
  },
  {
    path: "/afterSalesListSupp", //申请售后列表
    name: "AfterSalesListSupp",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      keepAlive:true,
      deepth: 1
    },
    component: () => import("@pages/supplier/afterSales/list.vue"),
  },
  {
    path: "/afterSalesDetailSupp", //售后详情
    name: "AfterSalesDetailSupp",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/afterSales/detail.vue"),
  },
  {
    path: "/afterSalesPaySupp", //售后-去支付
    name: "AfterSalesPaySupp",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/afterSales/pay.vue"),
  },
  {
    path: "/collChecklist", //协同清单列表
    name: "CollChecklist",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/supplier/collChecklist/index.vue"),
  },
];
