<template>
  <div id="footer" v-if="accountType == 1 || accountType == 4">
    <ul>
      <router-link tag="li" v-for="item in tabbars" :key="item.id" :to="item.path">
        <!-- <van-badge v-if="!item.active && item.id == 2 && $store.state.cartCount != 0" :content="$store.state.cartCount"
          max="99"></van-badge> -->
        <van-badge v-if="!item.active && item.id == 2 && carCount > 0" :content="carCount" max="99"
          color="#FE2C55" :class="{ 'vanBadgeOne': carCount < 10 }"></van-badge>
        <img class="tabbarImg" v-if="!item.active" :src="item.icon" alt="">
        <img class="tabbarImg" v-else :src="item.iconActive" alt="">
        <span>{{ item.text }}</span>
      </router-link>
    </ul>
  </div>
</template>

<script>
// import eventLib from '@/utils/documentEvent.js';

import * as orderApi from "@/api/order";
export default {
  name: "TabBar",
  // props: {
  //   carCount: {
  //     type: Number,
  //     default: 0,
  //   },
  // },
  watch: {
    '$route': { // $route可以用引号，也可以不用引号
      handler() {
        console.log(this.$route.path)
        if (this.$route.path == '/clientHome') {
          this.tabbars[0].active = true
          this.tabbars[1].active = false
          this.tabbars[2].active = false
          this.tabbars[3].active = false
          return
        }
        if (this.$route.path == '/suppClassificationOp') {
          this.tabbars[0].active = false
          this.tabbars[1].active = true
          this.tabbars[2].active = false
          this.tabbars[3].active = false
          return
        }
        if (this.$route.path == '/shopCart') {
          this.tabbars[0].active = false
          this.tabbars[1].active = false
          this.tabbars[2].active = true
          this.tabbars[3].active = false
          return

        }
        if (this.$route.path == '/mine') {
          this.tabbars[0].active = false
          this.tabbars[1].active = false
          this.tabbars[2].active = false
          this.tabbars[3].active = true
          return
        }
      },
      deep: true, // 深度监听
      immediate: true, // 第一次初始化渲染就可以监听到
    }
  },
  data() {
    return {
      hello: "",
      carCount: this.$store.state.cartCount,
      accountType: 1,
      active: '0',
      tabbars: [
        {
          id: 0,
          text: "首页",
          icon: require("@assets/image/tabbar10.png"),
          iconActive: require("@assets/image/tabbar11.png"),
          path: "/clientHome",
          active: true
        },
        {
          id: 1,
          text: "分类",
          icon: require("@assets/image/tabbar20.png"),
          iconActive: require("@assets/image/tabbar21.png"),
          path: "/suppClassificationOp?id=1",
          active: false
        },
        {
          id: 2,
          text: "购物车",
          icon: require("@assets/image/tabbar30.png"),
          iconActive: require("@assets/image/tabbar31.png"),
          path: "/shopCart",
          active: false
        },
        {
          id: 3,
          text: "我的",
          icon: require("@assets/image/tabbar40.png"),
          iconActive: require("@assets/image/tabbar41.png"),
          path: "/mine",
          active: false
        }
      ]
    };
  },
  mounted() {
    this.accountType = JSON.parse(
      sessionStorage.getItem("userInfoXpz")
    )?.accountType;
    this.init()

    let self = this
    this.bus.$on('getCarCountxxx', function (msg) {
      console.log(msg);
      self.carCount = msg
    })
  },
  methods: {
    //获取购物车列表数量
    async getCarCount() {
      let res = await orderApi.carCountInfo();
      if (res.status) {
        this.carCount = res.ret.count
        // this.$store.commit('handleMutationsAdd', this.carCount)
        // window.sessionStorage.setItem('cartCountXpz', JSON.stringify(this.carCount));
      } else {
        this.$toast(res.msg);
      }
    },
    async init() {
      //根据用户角色显示 要显示的角色首页 0运营端，1客户，2供应商
      let accountType = JSON.parse(
        sessionStorage.getItem("userInfoXpz")
      )?.accountType;
      if (accountType == 1 || accountType == 4) {
        //  客户端首页
        this.getCarCount()
      }
    },
  }

};
</script>

<style lang="scss">
#footer {
  width: 100%;
  // height: 1rem;
  padding: .1rem 0 .2rem;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #fff;
  z-index: 103;

  .van-badge {
    position: absolute !important;
    top: -10% !important;
    right: 27% !important;
    z-index: 10 !important;
    box-sizing: border-box;
    border: .02rem solid #FEFEFE;
    font-size: .2rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FEFEFE;
  }
  .vanBadgeOne {
    width: .32rem;
    height: .32rem;
    line-height: .31rem;
    border-radius: 50%;

}
}



#footer ul {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#footer ul li {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

#footer ul li span {
  font-size: .2rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #464646;
}

#footer ul li img {
  width: .48rem;
  height: .48rem;
}

#footer ul .router-link-active {
  span {
    color: #FE2C55;
  }
}

// /deep/ .van-badge {
//         width: .32rem;
//         height: .32rem;
//         line-height: .31rem;
//         background: #FE2C55;
//         border: .02rem solid #FEFEFE;
//         border-radius: 50%;
//         font-size: .2rem;
//         font-family: PingFang SC;
//         font-weight: 400;
//         color: #FEFEFE;
//         top: 0.06rem;
//         right: 0.02rem;
//       }

// /deep/ .van-badge{
// position: absolute;
// right: -0.1rem;
// }
</style>