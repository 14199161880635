// import Vue from 'vue'
// import Vuex from 'vuex'

// Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//     cartList: []
//   },
//   mutations: {
//     addToCart (state, goods) {
//       const index = state.cartList.findIndex(item => item.id === goods.id)
//       if (index === -1) {
//         state.cartList.push({
//           id: goods.id,
//           productName: goods.productName,
//           price: goods.price,
//           count: 1
//         })
//       } else {
//         state.cartList[index].count++
//       }
//     },
//     removeFromCart (state, id) {
//       const index = state.cartList.findIndex(item => item.id === id)
//       if (index !== -1) {
//         state.cartList.splice(index, 1)
//       }
//     }
//   },
//   actions: {
//     addToCart ({ commit }, goods) {
//       commit('addToCart', goods)
//     },
//     removeFromCart ({ commit }, id) {
//       commit('removeFromCart', id)
//     }
//   },
//   getters: {
//     cartList: state => state.cartList,
//     cartTotal: state => state.cartList.reduce((total, item) => total + item.count, 0),
//     cartAmount: state => state.cartList.reduce((total, item) => total + item.price * item.count, 0)
//   }
// })

// import Vue from 'vue'
// import Vuex from 'vuex'
// // 1. 导入购物车的 vuex 模块
// import moduleCart from './cart.js'
 
// Vue.use(Vuex)
 
// const store = new Vuex.Store({
//   // TODO：挂载 store 模块
//   modules: {
//     // 2. 挂载购物车的 vuex 模块，模块内成员的访问路径被调整为 m_cart，例如：
//     //    购物车模块中 cart 数组的访问路径是 m_cart/cart
//     m_cart: moduleCart,
//   },
// })
 
// export default store


import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);


let state = {
    cartCount:0,
    resetFlag:true,
 
    scrollPosition: 0, // 列表页滚动位置
    currentPage: 1, // 当前页码
}


// let actions = {
//     handleActions({}){
//         alert(1);
//     }
// }



let mutations = {
    handleMutationsFlag(state,newVal){
        state.resetFlag=newVal;
    },
    handleMutationsAdd(state,newVal){
        state.cartCount=state.cartCount+Number(newVal);
        window.sessionStorage.setItem('cartCountXpz', JSON.stringify(state.cartCount));
    },
    handleMutationsDel(state,newVal){
        state.cartCount=state.cartCount-Number(newVal);
        window.sessionStorage.setItem('cartCountXpz', JSON.stringify(state.cartCount));
    },

      // 更新滚动位置
      updateScrollPosition(state, position) {
        state.scrollPosition = position;
      },
       // 更新当前页码
    updateCurrentPage(state, page) {
        state.currentPage = page;
      },
 
}


let getters = {
  cartCount(state){
        return state.cartCount
    }
}
// vuex配置项  公共的内存空间
const store = new Vuex.Store({
    state,
    // actions,
    mutations,
    getters
})


export default store;